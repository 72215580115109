<template>
  <base-layout-two page-title="Reporting" page-default-back-link="/more" content-class="background-white">
    <section class="padding-x-16">
      <h1>Reports - Reservation Status</h1>
    </section>

    <!-- Search -->
    <section class="padding-x-16">
      <v-form @submit="fetchReservations">
        <ion-grid>
          <ion-row>
            <!-- Arrival Date -->
            <ion-col size="12" size-lg="5">
              <base-input label-text="Start Date *">
                <v-field name="start_date" v-slot="{ field }" v-model="start_date" :rules="required">
                  <ion-input name="start_date" v-bind="field" placeholder="" type="date"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="start_date" class="error-message">
              </v-error-message>
            </ion-col>

            <ion-col size="12" size-lg="5">
              <base-input label-text="End Date *">
                <v-field name="end_date" v-slot="{ field }" v-model="end_date" :rules="required">
                  <ion-input name="end_date" v-bind="field" placeholder="" type="date"
                    :min="minDepartureDate"></ion-input>
                </v-field>
              </base-input>
              <v-error-message name="end_date" class="error-message">
              </v-error-message>
            </ion-col>

            <ion-col size="12" size-lg="2">
              <ion-button type="submit" expand="block">Submit</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </v-form>
    </section>

    <!-- Table -->
    <section class="padding-x-16">
      <table width="100%">
        <thead>
          <tr style="font-weight: bold">
            <td>ID</td>
            <td>Status</td>
            <td>Confirmation</td>
            <td>Guest</td>
            <td>Mobile</td>
            <td>Arriving in</td>
            <td>Arrival</td>
            <td>Duration</td>
            <td>Departure</td>
            <td>Deposit Amount</td>
            <td>Outstanding Balance</td>
            <td>Docusign</td>
            <td>Dietary Preference</td>
            <td>Support Person</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reservation, index) in reservations" :key="reservation.id" :bgcolor="index % 2 ? '#fff' : '#fff'">
            <td>{{ reservation.id }}</td>

            <td :bgcolor="reservation.status !== 'confirmed' ? 'red' : ''">
              {{ reservation.status }}
            </td>

            <td>{{ reservation.confirmation }}</td>

            <td>{{ reservation.user.fullname }}</td>

            <td>{{ reservation.user.mobile_number }}</td>

            <td>{{ arrivingIn(reservation) }}</td>

            <td>
              {{ formatDate(reservation.arrival_date, "MMM d") }}
            </td>

            <td>
              {{
                Math.floor(differenceInHours(
                  new Date(reservation.departure_date),
                  new Date(reservation.arrival_date)) / 24)
              }}
              nights
            </td>

            <td>
              {{ formatDate(reservation.departure_date, "MMM d") }}
            </td>

            <td>${{ formatCurrency(reservation.price_deposit) }}</td>

            <td :bgcolor="reservationBalance(reservation) < 0 ? 'red' : ''">
              ${{ formatCurrency(reservationBalance(reservation)) }}
            </td>

            <td :bgcolor="
              reservation.docusign_envelope_status === 'completed'
                ? ''
                : 'red'
            ">
              {{ reservation.docusign_envelope_status }}
            </td>

            <td>{{ reservation.user.profile.dietary_preferences }}</td>

            <td :bgcolor="
              reservation.user.profile.support_person_name ? '' : 'red'
            ">
              {{ reservation.user.profile.support_person_name }}
              {{
                reservation.user.profile.support_person_number
                ? `(${reservation.user.profile.support_person_number})`
                : ``
              }}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </base-layout-two>
</template>

<script>
import { IonGrid, IonRow, IonCol, IonButton, IonInput } from "@ionic/vue";
import * as yup from "yup";
import { format, addDays, subDays, differenceInHours } from "date-fns";
import { formatCurrency, formatDate } from "@/util/helpers";
import { Field, Form, ErrorMessage } from "vee-validate";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonInput,
    VForm: Form,
    VField: Field,
    VErrorMessage: ErrorMessage,
  },

  data() {
    return {
      reservations: [],
      start_date: null,
      end_date: null,
      minDepartureDate: null,
      required: yup.string().required(),
      format,
      formatCurrency,
      formatDate,
      differenceInHours,
    };
  },

  async ionViewWillEnter() {
    this.start_date = format(subDays(new Date(), 1), "yyyy-MM-dd");
    this.end_date = format(addDays(new Date(), 90), "yyyy-MM-dd");

    await this.fetchReservations();
  },

  methods: {
    // Params is an object with keys arrival_date_before, arrival_date_after, departure_date_before, departure_date_after
    async fetchReservations() {
      // Add a Query String to customize reservations to fetch
      let reservationParams = {
        departure_date_after: this.start_date,
        arrival_date_before: this.end_date,
      };

      let queryString = "";
      if (reservationParams)
        queryString = Object.keys(reservationParams)
          .map((key) => key + "=" + reservationParams[key])
          .join("&");

      await this.axios
        .get(`${process.env.VUE_APP_API}/reservations?${queryString}`)
        .then((response) => {
          this.reservations = response.data.reservations;

          this.reservations = this.reservations.sort((a, b) => {
            return new Date(a.arrival_date) - new Date(b.arrival_date);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    reservationBalance(reservation) {
      if (reservation.folio.transactions) {
        reservation.folio.transactions = reservation.folio.transactions.sort(
          (a, b) => {
            return new Date(a.date) - new Date(b.date);
          }
        );

        // Get the Line Items
        let line_items = reservation.folio.transactions.filter(
          (x) => x.type === "line_item"
        );

        // Get the Payments
        let payments = reservation.folio.transactions.filter((x) => {
          if (
            x.type === "charge" &&
            x.stripe_payment_intent_status === "succeeded"
          )
            return true;
          return false;
        });

        // Calculate Total
        let total = line_items.reduce(
          (partial_sum, a) => partial_sum + a.price_total,
          0
        );

        // Calculate Payments Total
        let paymentsTotal = payments.reduce(
          (partial_sum, a) => partial_sum + a.amount,
          0
        );

        // Calculate Balance
        let balance = Number(paymentsTotal) + Number(total);

        return Number(balance);
      }
    },

    arrivingIn(reservation) {
      let days =
        Math.floor(differenceInHours(
          new Date(reservation.arrival_date),
          new Date()) / 24)

      return `${Math.abs(days)} ${days < 0 ? "days ago" : "days"}`;
    },
  },
};
</script>

<style scoped>
table,
thead {
  border: 1px solid #6a6a6a;
}

td {
  border-top: 1px solid grey;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}
</style>